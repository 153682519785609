section.banner {
    background: {
        image: url('../images/banner.jpg');
        size: cover;
        position: center; }
    height: 600px;
    padding-top: 50px;

    figure {
        text-align: center;
        margin-bottom: 20px; }

    .banner-title {
        margin: 0;
        line-height: 60px;
        font: {
            size: 48px; }
        color: #fff;
        text-align: center; }

    @media (max-width: $device-md) {
        .banner-title {
            margin: 0 15px; } }

    @media (max-width: $device-sm) {
        height: 500px;
        padding-top: 30px;

        .banner-title {
            font: {
                size: 35px; }
            line-height: 50px; } }

    @media (max-width: $device-xs) {
        background: {
            image: url('../images/banner_mobile.jpg'); }
        height: 700px;

        .banner-title,
        figure {
            display: none;
            // font:
            //     size: 25px
 } } }            // line-height: 35px

section.procedurs {
    background: linear-gradient(to top, #F0FAFF, #fff);

    .inner-wrapper {
        max-width: 730px;
        margin: 0 auto; }

    h4 {
        font: {
            size: 22px; }
        color: $blue;
        line-height: 28px;
        margin: 0 0 20px 0; }

    ul {
        counter-reset: x;
        font: {
            weight: 500; }

        li {
            padding-left: 2.3rem;
            counter-increment: x;
            position: relative;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0; }

            &::before {
                content: counter(x) '.';
                display: inline-block;
                line-height: 1;
                position: absolute;
                left: 0;
                top: 5px; } } }

    @media (max-width: $device-sm) {
        .inner-wrapper {
            margin: 0 15px; } } }

section.delivery-area {
    position: relative;

    &::before {
        content: "";
        display: inline-block;
        width: 285px;
        height: 570px;
        background: {
            image: url('../images/half-milk.png');
            size: contain;
            repeat: no-repeat;
            position: center; }
        position: absolute;
        top: -40%;
        right: 0; }

    .delevery-covering-container {
        margin-top: 40px;

        > p {
            margin: 0 0 15px 17%;
            color: $blue;
            font: {
                size: 22px;
                weight: 800; } }

        .delivery-row {
            display: flex;
            align-items: center;
            box-shadow: rgba($blue, .14) 0 12px 9px -6px;
            border-radius: 5px;
            font: {
                size: 16px;
                weight: 600; }
            color: #757575;
            border: 1px solid rgba(#E0E0E0, .6);
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0; }

            &.active {
                background: $green;

                .cities {
                    color: #fff; } }

            .area {
                max-width: 230px;
                min-width: 230px;
                position: relative;
                padding: 20px;
                text-align: center;

                &::after {
                    content: "";
                    @include position(absolute, 0 0 0 null);
                    width: 1px;
                    height: 80%;
                    margin: auto;
                    background: rgba(#000, .1); } }

            .cities {
                padding: 20px; } } }

    @media (max-width: $device-lg) {
        &::before {
            width: 200px; } }

    @media (max-width: $device-md) {
        &::before {
            display: none; } }

    @media (max-width: 680px) {
        .delevery-covering-container {
            > p {
                margin: 0 0 15px 0;
                text-align: center; }

            .delivery-row {
                flex-direction: column;

                .area {
                    width: 100%;
                    max-width: 100%;

                    &::after {
                        top: auto;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        width: 90%; } }

                .cities {
                    text-align: center; } } } } }

section.order {
    background: {
        image: url('../images/order-bg.jpg');
        size: cover;
        position: center; }

    .order-container {
        max-width: 980px;
        margin: 40px auto 0;

        .left-side,
        .right-side {
            flex: 1;
            max-width: 500px;
            width: 100%;
            margin: 0 auto; }

        .left-side {
            margin-bottom: 40px; }

        form {
            width: 100%;
            margin: auto;

            .group {
                margin-bottom: 30px;
                position: relative;

                &:last-of-type {
                    margin-bottom: 0; }

                input,
                select,
                textarea {
                    font: {
                        weight: 700; }
                    color: #474747;
                    width: 100%;
                    padding: 12px 20px;
                    border: 1px solid #eee;
                    background-color: #F9F9F9;
                    border-radius: 5px;
                    transition: all .3s ease-in-out;

                    &:focus,
                    &:active {
                        outline: none; }

                    &.error {
                        border-color: #e4312b !important; } }

                input {
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 35px white inset !important; } }

                label {
                    margin: 0;
                    font: {
                        weight: 400; }

                    &.error {
                        position: absolute !important;
                        top: 100%;
                        left: 20px !important;
                        color: #e4312b !important;
                        font: {
                            weight: 600; } } }

                textarea {
                    resize: none;

                    &:focus,
                    &.has-value {
                        background: #fff;
                        border: 1px solid #0045A8; } }

                &.select-group {
                    select {
                        appearance: none;
                        color: #7A7A7A;
                        font: {
                            weight: 400; }
                        background: {
                            image: url('../images/misc/angle-down.svg');
                            size: 10px;
                            repeat: no-repeat;
                            position: 95% center; }

                        &.has-value {
                            background: {
                                color: #fff; }
                            border: 1px solid #0045A8;
                            font: {
                                weight: 700; }
                            color: #474747; }

                        &.error {
                            margin-bottom: 40px; }

                        + label.error {
                            top: 60%; } } }

                &.fieldset-group {
                    position: relative;

                    input {
                        ~ .input-label {
                            @include position(absolute, 50% null null 20px);
                            transform: translateY(-50%);
                            pointer-events: none;
                            color: #7A7A7A;
                            transition: all .3s ease-in-out; }

                        &:focus,
                        &.has-value {
                            padding-top: 30px;
                            border: 1px solid #0045A8;
                            background: #fff;

                            ~ .input-label {
                                top: 20px;
                                font: {
                                    weight: 700; }
                                color: $blue;
                                background: #fff;
                                padding: 0; } } } }

                &.inline-group {
                    @extend %flex-center-around;

                    p {
                        color: #5C5C5C;
                        margin: 0;
                        font: {
                            size: 14px; }
                        text-align: left; } }

                .radio-group {
                    display: flex;
                    align-items: center;

                    .radio-button {
                        input {
                            position: absolute;
                            visibility: hidden;
                            @include size(.1px);

                            + label {
                                font: {
                                    size: 13px; }
                                color: #5C5C5C;
                                position: relative;
                                padding-left: 2.3rem;
                                cursor: pointer;

                                &::before,
                                &::after {
                                    content: "";
                                    display: inline-block;
                                    @include size(15px);
                                    background: #fff;
                                    border: 1px solid #eee;
                                    border-radius: 50%;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%); }

                                &::after {
                                    background: {
                                        color: #0045A8;
                                        image: url('../images/misc/correct-icon.svg');
                                        size: 9px;
                                        repeat: no-repeat;
                                        position: center; }
                                    border-color: #0045A8;
                                    opacity: 0; } }

                            &:checked {
                                + label {
                                    &::after {
                                        opacity: 1; } } } }

                        &:first-child {
                            margin-right: 20px; } } }

                &.button-group {
                    .button {
                        margin: auto; } } } }

        .right-side {
            .product-group {
                margin-bottom: 25px;

                &:last-of-type {
                    margin-bottom: 0; }

                h4 {
                    font: {
                        size: 18px;
                        weight: 700; }
                    margin: 0; }

                .product {
                    background: #F9F9F9;
                    border-radius: 5px;
                    padding: 8px 15px;
                    font: {
                        size: 16px;
                        weight: 600; }
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    figure {
                        img {
                            max-width: 100px;
                            margin-right: 20px; } }

                    .product-info {
                        flex: 1; }

                    .product-name {
                        flex: 1;
                        padding-right: 15px; }

                    .product-cost {
                        margin-right: 20px;
                        font: {
                            size: 14px; }
                        color: #8c8c8c;

                        .cost {
                            padding-right: 10px;

                            &::after {
                                content: "/-"; } } } } } } }

    @media (max-width: 980px) {
        padding-left: 15px;
        padding-right: 15px; }

    @media (max-width: $device-sm) {
        .order-container {
            .right-side {
                margin-bottom: 30px; } } }

    @media (max-width: $device-sm) {
        .order-container {
            display: flex;
            flex-direction: column;

            .right-side {
                order: -1;

                .product-group {
                    .product {
                        flex-direction: column;
                        padding: 15px;

                        .product-name {
                            padding: 0; }

                        .product-cost {
                            margin: 10px 0;

                            .cost {
                                padding-right: 0; } } } } } } } }



