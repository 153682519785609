//device-size
$device-xl: 1400px;
$device-lg: 1200px;
$device-md: 992px;
$device-sm: 768px;
$device-xs: 480px;

// fonts
$fa-font-path: "../fonts/font-awesome/fonts";

$quicksand: 'Quicksand', sans-serif;

//colors
$blue: #0A1F8F;
$green: #4CC069;
$lightgray: #979797;

//ONLY for multiline text ellipsis
$text-font-size: 16px;
$text-line-height: 1.4;
$text-lines-to-show: 3;

