%flex-center {
	display: flex;
	align-items: center;
	justify-content: center; }

%flex-center-between {
	display: flex;
	align-items: center;
	justify-content: space-between; }

%flex-center-around {
	display: flex;
	align-items: center;
	justify-content: space-around; }
