//	Headings
.gigantic, .huge, .large, .bigger, .big,
h1, h2, h3, h4, h5, h6 {
    font: {
 }        // family: $proximaBold
    font-weight: bold; }

.gigantic {
    font-size: 110px;
    line-height: 1.09;
    letter-spacing: -2px; }

.huge, h1 {
    font-size: 68px;
    line-height: 1.05;
    letter-spacing: -1px; }

.large, h2 {
    font-size: 42px;
    line-height: 1.14; }

.bigger, h3 {
    font-size: 26px;
    line-height: 1.38; }

.big, h4 {
    font-size: 22px;
    line-height: 1.38; }

.small, small {
    font-size: 10px;
    line-height: 1.2; }

// Custom Headings

.medium-title {
    font: {
        size: 22px;
        weight: 700; }
    color: $blue;
    margin: 0 0 20px 0; }

.section-title {
    font: {
        size: 48px;
        weight: 700; }
    margin: 0;
    line-height: 60px;
    color: $blue;
    text-align: center;

    @media (max-width: $device-xs) {
        font: {
            size: 35px; }
        line-height: 45px; } }



.sub {
    font: {
        size: 18px;
        weight: 500; }
    max-width: 580px;
    text-align: center;
    margin: 20px auto; }


.stand-alone {
    text-transform: none !important; }

.uppercase {
    text-transform: uppercase !important; }

// Basic Text Style

p {
    margin: 0 0 20px 0; }

em {
    font-style: italic; }

strong {
    font-weight: bold; }

hr {
    border: solid rgba(black, .5);
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 30px;
    height: 0; }

// Links

a {
    display: inline-block;
    color: black;
    text-decoration: none;
    outline: 0;

    &:hover,
    &:focus {
        color: lighten(black, 20%);
        text-decoration: none; } }

ul {
    margin: 0;
    padding: 0;
    list-style: none; }

// selection
// ::selection
//     background: lighten($black, 5%)
//     color: #fff


body {
    background-color: white;
    font: {
        family: $quicksand;
        size: 14px; }
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    position: relative; }
