.section-padding {
    padding: 70px 0; }

.number-spinner {
    display: flex;
    align-items: center;

    input {
        border: none;
        outline: none;
        width: 50px;
        text-align: center;
        background: #F9F9F9; }

    button {
        @extend %flex-center;
        background: $blue;
        border: none;
        border-radius: 50%;
        outline: none;
        @include size(30px);

        .fa {
            -webkit-text-stroke: 3px $blue;
            color: #fff;
            font: {
                size: 20px; } } } }

.icon-group {
    display: flex;
    align-items: center;

    .icon {
        @extend %flex-center;
        @include size(44px);
        background: #F0F3F4;
        border-radius: 50%;
        margin-right: 12px;

        .fa {
            font: {
                size: 18px; }
            color: $blue; } }

    a {
        font: {
            size: 16px;
            weight: 600; } } }

.confirm-order {
    background: $blue;
    @include position(fixed, null 0 0 0);
    display: none;

    p.note {
        padding: 8px;
        background: $green;
        color: #fff;
        font: {
            size: 16px;
            weight: 600; }
        text-align: center;
        margin: 0; }

    .inner-wrapper {
        max-width: 450px;
        margin: 0 auto;
        padding: 30px 20px;
        display: flex;
        justify-content: center;

        .left-side {
            margin-right: 60px;

            p {
                font: {
                    weight: 600; }
                line-height: 1.2;
                margin: 0;
                color: #fff;

                &:first-child {
                    font: {
                        size: 18px; } }

                &:last-of-type {
                    font: {
                        size: 22px;
                        weight: 800; }
                    text-transform: uppercase; } } }

        .right-side {
            button.btn-confirm {
                font: {
                    size: 16px;
                    weight: 600; }
                color: #fff;
                letter-spacing: 0.5px;
                background: $green;
                outline: none;
                border-radius: 5px;
                padding: 13px 40px;
                border: none;

                &.disabled {
                    filter: grayscale(1);
                    cursor: not-allowed;
                    pointer-events: none; } } } }

    @media (max-width: $device-xs) {
        .inner-wrapper {
            .left-side {
                margin-right: 20px; }

            .right-side {
                button.btn-confirm {
                    font: {
                        size: 14px; }
                    padding: 13px 30px; } } } } }

.popup-container {
    @include position(fixed, 0 0 0 0);
    background: rgba($blue, .5);
    z-index: 20;
    display: none;

    &.loading-popup {
        .popup-wrapper {
            background: transparent; } }

    .popup-wrapper {
        background: #fff;
        border-radius: 5px;
        padding: 30px;
        max-width: 450px;
        min-width: 300px;
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
        text-align: center;

        h4 {
            font: {
                size: 22px;
                weight: 700; }
            color: $green; }

        p {
            margin: 0;
            font: {
                size: 18px; }

            span {
                font: {
                    weight: 800; } } } }

    a.popup-close {
        @include position(absolute, 15px 15px null null);
        display: flex; } }
