footer {
    .top-row {
        padding: 40px 0;

        .container {
            display: flex;
            justify-content: space-between;

            .left-side {
                flex: 1;

                .icons-row {
                    display: flex;

                    .icon-group {
                        margin-right: 70px;

                        &:last-child {
                            margin-right: 0; } }

                    .social-group {
                        display: flex;
                        align-items: center;

                        a {
                            @extend %flex-center;
                            @include size(44px);
                            background: #F0F3F4;
                            border-radius: 50%;
                            margin-right: 12px;

                            .fa {
                                font: {
                                    size: 20px; }
                                color: $blue; }

                            &:last-of-type {
                                margin-right: 0; } } } } } } }

    .bottom-row {
        background: #DCE0E2;
        padding: 10px 0;

        .container {
            @extend %flex-center-between;

            p {
                margin: 0;

                &:first-child {
                    flex: 1; } } } }

    @media (max-width: $device-md) {
        .top-row {
            .container {
                .left-side {
                    .icons-row {
                        flex-direction: column;

                        .icon-group {
                            margin: 0 0 20px 0; } } } } } }


    @media (max-width: $device-xs) {
        .top-row {
            .container {
                flex-direction: column;

                .left-side {
                    margin-bottom: 40px;
                    text-align: center;

                    .icons-row {
                        align-items: center; } }

                .right-side {
                    text-align: center;

                    .icon-group {
                        justify-content: center; } } } }

        .bottom-row {
            .container {
                flex-direction: column; } } } }
